import React from "react"
import Layout from "@openarchitex/gatsby-theme-minimal-portfolio/src/components/layout"
import Footer from "../@openarchitex/gatsby-theme-minimal-portfolio/components/footer"
import Header from "../@openarchitex/gatsby-theme-minimal-portfolio/components/header"
import SEO from "../components/seo"
import NotFoundImage from '../images/404.svg'
import { Image, Box, Text } from 'theme-ui'

const NotFoundPage = () => {
  return (
    <div>
      <SEO title="ページが見つかりません" />
      <Header />
      <Layout>
        <Box
          pt={4}
          sx={{ textAlign: 'center' }} >
          <Image  src={NotFoundImage} sx={{ width: '80%' }} />
        <Text
            pt={5}
            sx={{
                fontSize: 6,
                fontWeight: 'bold',
                color: 'base'
            }}>
                ページが見つかりません
        </Text>
        <Text
          pt={3}
          pb={6}
          sx={{
            fontSize: 2,
            fontWeight: 'bold',
          }}>
          リンクに問題があるか、ページが削除された可能性があります。
          </Text>
        </ Box>
      </Layout>
      <Footer />
    </div>
  )
}

export default NotFoundPage
